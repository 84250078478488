 // Gradient background decoration styles
 .gradient-background-decoration-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 1055px;
    z-index: -1;
}
.gradient-background-decoration-inner {
    width: 47%;
    height: 100%;
    position: relative;
    margin-left: auto;

    &::before {
        content: '';
        position: absolute;
        background-image: linear-gradient(359.6deg, #503690 -28.47%, #EA1A6F 67.91%);
        border-radius: 200px;
        transform: rotate(15deg);
        width: 300%;
        height: 115%;
        z-index: -1;
        @media only screen and(max-width: 1920px) {
            height: 100%;
        }
    }
}
.hero-background-decoration {
    @media only screen and(max-width: 768px) {
        display: none;
    }
}
.circle--hero-1 {
    position: absolute;
    top: 175px;
    right: 242px;
}
.circle--hero-2 {
    position: absolute;
    top: 198px;
    left: 95px;
    z-index: 1;
}
.circle--hero-3 {
    position: absolute;
    top: 380px;
    right: 163px;
}
.circle--hero-4 {
    position: absolute;
    top: 433px;
    right: 286px;
    z-index: 1;
}
.circle--hero-5 {
    position: absolute;
    left: 103px;
    top: 447px;
}
.circle--hero-6 {
    position: absolute;
    top: 608px;
    right: 168px;
}
.circle--hero-7 {
    position: absolute;
    top: 585px;
    left: 139px;
}
.circle--hero-8 {
    position: absolute;
    top: 637px;
    left: 43px;
}
.circle--hero-9 {
    position: absolute;
    top: 771px;
    right: 275px;
}
