.slick-arrow {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    border: 1px solid $main_text_color;
    border-radius: 50%;
    color: $main_text_color;
    width: 60px;
    height: 60px;
    font-size: 18px;
    cursor: pointer;
    transition: .4s ease;
}

.slick-prev--features {
    bottom: 45px;
    left: calc((100% - 1620px) / 2);
    &:hover {
        background-color: $bg-transparent-color;
    }
    @media only screen and(max-width: 1660px) {
        left: 30px;
    }
    @media only screen and(max-width: 960px) {
        bottom: -60px;
        left: 0;
        right: 90px;
        margin-left: auto;
        margin-right: auto;
    }
}
.slick-next--features {
    bottom: 45px;
    left: calc((100% - 1620px) / 2 + 100px);
    &:hover {
        background-color: $bg-transparent-color;
    }
    @media only screen and(max-width: 1660px) {
        left: 130px;   
    }
    @media only screen and(max-width: 960px) {
        bottom: -60px;
        left: 90px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
}

.slick-prev--app {
    bottom: -97px;
    left: 0;
    right: 90px;
    margin-left: auto;
    margin-right: auto;
    &:hover {
        background-color: $main_color_opacity;
    }
}

.slick-next--app {
    bottom: -97px;
    left: 90px;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    &:hover {
        background-color: $main_color_opacity;
    }
}
.slick-dots {
    display: flex;
    justify-content: center;
    color: $main_text_color;
    list-style-type: none;
    padding-left: 0;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    li {
        width: 26px;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: $main_text_color;
        text-indent: -9999px;
        cursor: pointer;
        margin: auto;
    }
    li.slick-active {
        width: 26px;
    }
    li.slick-active button {
        background-color: $main_color;
        width: 26px;
        border-radius: 5px;
        transition: width .4s ease-in-out;
    }
}