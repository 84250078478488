.features-section {
    width: 100%;
    padding-bottom: 650px;
    padding-top: 5px; 
    margin-bottom: -590px;
    margin-top: 50px;
    overflow: hidden;
    z-index: -1;
    @media only screen and(max-width: 960px) {
        padding-bottom: 970px;
    }
}
.features-section-inner {
    position: relative;
}
.features-text-description-wrapper {
    position: relative;
    width: 34%;
    @media only screen and(max-width: 1200px) {
        width: 45%;
    }
    @media only screen and(max-width: 960px) {
        width: 90%;
    }
}
.features-background-decoration {
    right: 0;
    top: 110px;
    transform: rotate(-75deg);
    z-index: 1; 
    @media only screen and(max-width: 1200px) {
        top: 50px;   
    }
}
.features-gradient-circle-item {
    transform: rotate(15deg);
    height: 25%;
    width: 100%;
    display: flex;
}
.features-gradient-circle-item {
    justify-content: flex-end;
    .small-circle {
        margin-right: 138px;
        margin-top: 97px;
    }
}
.features-gradient-circle-item-2 {
    justify-content: center;
    .medium-circle {
        margin-top: 109px;
        margin-right: 247px;
    }
}
.features-gradient-circle-item-3 {
    justify-content: center;
    .big-circle {
        margin-top: 277px;
        margin-right: 125px;
    }
}
.features-gradient-circle-item-4 {
    justify-content: flex-end;
    .small-circle {
        margin-top: 101px;
        margin-right: 293px;
    }
}
.content-wrapper {
    padding-top: 128px;
    position: relative;
    z-index: 9;   
    padding-right: 20px;
}
.features-slider-wrapp {
    position: absolute;
    top: 140px;
    width: 100%;
    right: 0;
    bottom: 0;
    @media only screen and(max-width: 960px) {
        top: 410px;
        z-index: 1;
    }
}
.features__paragraph {
    margin-bottom: 23px;
}
.features-slider {
    @media only screen and(max-width: 480px) {
        width: 100%;
    }
}

.features-card__img {
    img {
        margin: 0 auto;
    }
}
.features-card {
    max-width: 383px;
    max-height: 358px;
    margin: 50px 8px 40px 0;
   
    @media only screen and(max-width: 480px) {
        max-width: 100%;
    }
}
.features-card__inner {
    padding: 60px 50px 60px 40px;
    @media only screen and(max-width: 960px) {
        padding: 30px;
    }
}
.features-card__title {
    margin-top: 44px;
    margin-bottom: 20px;
}
.features-card__paragraph {
    @media only screen and(max-width: 1024px) {
        font-size: 14px;
    }
}
.features-card__inner {
    text-align: center;
}