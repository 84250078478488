
// Color variables
$gradient-background-decoration: linear-gradient(359.6deg, #503690 -28.47%, #EA1A6F 67.91%);
$main_text_color: #fff;
$secondary_text_color: #F1F1F1;
$main_color: #E62D78;
$bg_color: #28292C; 
$border_color: #878787;

$bg-transparent-color: rgba(255, 255, 255, 0.25);
$bg-transparent-color-light: rgba(255, 255, 255, 0.1);
$bg-transparent-color-dark: rgba(0, 0, 0, 0.7);
$main_color_opacity: rgba(230,45,120, 0.4);