.footer {
    color: $main_text_color;
    font-family: "SFProDisplayRegular";
    padding-top: 30px;
}
.logo-wrapp--footer {
    max-width: 168px;
    img {
        margin-top: 10px;
    }
    @media only screen and(max-width: 1380px) {
        display: block;
        margin: 0 auto;
    }
    @media only screen and(max-width: 1000px) {
        margin: 0;
     }
     @media only screen and(max-width: 800px) {
       margin: 0 auto;
    }
}
.row--footer {
    justify-content: space-between;
    @media only screen and(max-width: 1000px) {
       justify-content: center;
       align-items: center;
    }
}
.footer-inner {
    background-color: $bg-color;
    padding: 40px 0 0 0;
}
.footer__text-wrapp {
    max-width: 225px;
    margin-top: 22px;
    @media only screen and(max-width: 1380px) {
        max-width: 100%;
    }
}
.footer__flex-col {
    margin-bottom: 50px;
}
.footer__flex-col--1 {
    @media only screen and(max-width: 1380px) {
        max-width: 100%;
        flex-basis: 100%;
        text-align: center;
    }
    @media only screen and(max-width: 1000px) {
        max-width: 30%;
        flex-basis: 30%;
        text-align: left;
    }
    @media only screen and(max-width: 800px) {
        max-width: 100%;
        flex-basis: 100%;
        text-align: center;
    }
}
.footer__flex-col--2 {
    margin-right: 45px;
    @media only screen and(max-width: 1380px) {
        max-width: 55%;
        flex-basis: 55%;
        margin-right: 0;
    }
    @media only screen and(max-width: 1000px) {
        max-width: 70%;
        flex-basis: 70%;
    }
    // @media only screen and(max-width: 800px) {
    //     max-width: 55%;
    //     flex-basis: 55%;
    // }
    @media only screen and(max-width: 800px) {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.footer__flex-col--3 {
    @media only screen and(max-width: 1380px) {
        max-width: 40%;
        flex-basis: 40%;
    }
    @media only screen and(max-width: 1000px) {
        max-width: 60%;
        flex-basis: 60%;
    }
    @media only screen and(max-width: 800px) {
        max-width: 100%;
        flex-basis: 100%;
    }
}
.nav--footer {
    @media only screen and(max-width: 800px) {
        display: block;
    }
    @media only screen and(max-width: 550px) {
        display: none;
    }
    .navbar-list {
        position: relative;
        padding-bottom: 25px;
        padding-left: 0;
        margin-left: 25px;

        @media only screen and(max-width: 800px) {
            margin-bottom: 50px;
            justify-content: center;
            margin-left: 0;
        }
    
        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $main_text_color;
            opacity: .5;
            bottom: 0;
        }
        .list-item {
            margin-right: 47px;
            margin-left: 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .link-item {
            transition: .4s ease-in-out;
            font-size: 16px;
            &:hover {
                color: $main_color;
            }
            &:before {
                content: none;
            }
        }
    }
}
.download-app--footer {
    padding: 0;
    justify-content: flex-start;
    margin-left: 28px;
    @media only screen and(max-width: 800px) {
        margin-left: 0;
     }
    .download-app-inner {
        padding: 0;
        @media only screen and(max-width: 800px) {
            margin: 0 auto;
         }
    }
    .download-app__subtitle {
        font-size: 18px;
        letter-spacing: 0;
        margin-top: 10px;
        margin-bottom: 27px;
        @media only screen and(max-width: 800px) {
            text-align: center;
        }  
    }
    .download-btns-wrapper {
       white-space: nowrap;
       @media only screen and(max-width: 360px) {
        white-space: normal;
        text-align: center;
        }
    }
    .dwn-btn {
        @media only screen and(max-width: 667px) {
            margin-right: 10px;
        }
        @media only screen and(max-width: 360px) {
            margin-right: 0;
        }
    }
}

.footer__icons-wrapp {
    border: 1px solid $main_color;
    border-radius: 20px;
    padding: 28px 50px 28px 50px;
    margin-top: 12px;
    @media only screen and(max-width: 650px) {
        padding: 20px;   
    }
    img {
        margin-right: 15px;
    }
}
.social-media-icons--footer {
    margin-top: 25px;
}
.footer__icons {
    margin-bottom: 30px;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and(max-width: 568px) {
        margin-bottom: 0;
    }
}
.footer__icon {
    white-space: nowrap;
    @media only screen and(max-width: 568px) {
        max-width: 100%;
        flex-basis: 100%;   
        margin-bottom: 30px;
    }
}
.footer__icon--first {
    margin-right: 10px;
}
.footer__icon--second {
    margin-right: 30px;
}

.footer__icons-mail {
    @media only screen and(max-width: 568px) {
        margin-left: 0;
    }
}
.footer-copyright {
    text-align: center;
    padding: 2px 0;
    font-size: 12px;
}