.app-section {
    width: 100%;
    margin-bottom: -250px;
    margin-top: 50px;
    padding: 50px 0 100px 0;
    position: relative;
    @media only screen and(max-width: 768px) {
        padding: 20px 0;
        margin-top: 0;
    }
}
.app-text-wrapper {
    position: relative;
    z-index: 10;
}
.app-slider {
    z-index: 10;
    @media only screen and(max-width: 480px) {
        width: 100%;
    }
}
.app-preview {
    margin: 0 0 15px;
    max-width: 384px;
    cursor: pointer;
    transition: .4s ease-in-out;
    @media only screen and(max-width: 480px) {
        max-width: 100%;
        width: 100%;
    }
     &:hover {
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 1);
    }
}
.app-img {
    border-radius: 20px;
    max-width: 100%;
    height: auto;
    @media only screen and(max-width: 480px) {
        width: 100%;
    }
}
