.hidden-nav {
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;  
    background-color: $main_color;
    transition: ease-in-out all .5s;
    z-index: 1;

    .navbar__inner {
        display: block;
        margin: auto;
        width: 50%;
        padding-top: 40%;
    }
    
    .list-item {
        font-size: 40px;
        line-height: 1em;
        margin-bottom: 15px;
        margin-left: 0;
    }
    .link-item {
        &:hover {
            color: $secondary_text_color;
        }
    }
}
.close {
    position: absolute;
    right: 7%;
    top: 2%;
    color: $main_text_color;
    font-size: 40px;
    font-family: sans-serif;
    font-weight: bold;
    cursor: pointer;
}
.navbar-active {
    opacity: 1;
    visibility: visible;
    height: 100vh;
}
